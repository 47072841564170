div {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body,
html {
  margin: 0;
  padding: 0;
  background: #fcf4ed;
  color: #fff;
  font-family: 'Sofia Sans Extra Condensed', 'Arial', sans-serif;
  text-align: center;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 30px;
  height: 100%;
  /* text-transform: uppercase; */
  overscroll-behavior: none;
}
a {
  color: #fff;
}
h1,
h2 {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0px;
  font-size: 28px;
}
p {
  margin: 0px;
}
.container {
  display: flex;
  background: linear-gradient(to bottom right, #8f49f9, #d133e7);
  min-width: 300px;
  width: 100%;
  min-height: 100%;
  /* border-radius: 10px; */
  max-width: 480px;
  flex-direction: column;
  margin: 0px auto;
  padding: 10px 10px 150px;
  gap: 15px;
  align-items: center;
}
.logo {
  position: relative;
  height: 80px;
  z-index: 0;
}
@media screen and(max-width:376px) {
  .logo {
    height: 80px;
  }
}
.logo.smaller {
  height: 50px;
}
.fipo {
  text-decoration: none;
}
.brand-image {
  width: 100%;
  /* max-height: 380px; */
  object-fit: contain;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  border: 4px solid #9c27b0;
  color: #333;
  text-align: center;
  font-family: 'Sofia Sans Extra Condensed', 'Arial', sans-serif;
  font-size: 24px;
  font-weight: bolder;
  line-height: 47px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  width: 90%;
  margin: 0px auto;
}
.button.fixed {
  position: fixed;

  bottom: 20px;
}
.input-file {
  display: none;
}
.file-input-label-text {
  font-size: 16px;
  letter-spacing: 2px;
}
/* scratch card page */
.scratch-container {
  position: fixed;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  display: flex;
  background: linear-gradient(to bottom right, #8f49f9, #d133e7);
  max-width: 425px;
  max-height: 851px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  justify-content: center;
}
.scratch-card {
  padding-top: 20px;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 300px;
  max-height: 100vh;
  max-width: 100vw;
  background-size: 80%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
}
canvas.scratch-layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 2;
}
.ready-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
  width: 300px;
  max-width: 300px;
  height: 300px;
  background: #a4ffe7;
  border: 7px solid #fff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.4);
}
img.uploaded-image {
  display: none;
  max-width: 100%;
  max-height: 100%;
  max-height: 340px;
  max-width: 300px;
  border: 4px solid #fff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.4);
}
#uploadButton {
  line-height: 44px;
  height: 47px;
  position: relative;
  overflow: hidden;
}
.uploading {
  flex: 1 1 auto;
  max-height: 47px;
}
.progress,
.preloader {
  display: none;
  z-index: 3;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  line-height: 40px;
  /* color: #fff; */
  text-align: center;
}
.uploading .preloader {
  display: block;
  position: absolute;
  color: #333;
}
.uploading .default {
  display: none;
  line-height: 36px;
}
.uploading .progress {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  z-index: 2;
  height: 100%;
  background-color: #86eac8;
  width: 0px;
  transition: width 0.3s ease;
}
.cover-slider {
  overflow: hidden;
  background-color: #fff;
  padding-top: 10px;
  border-radius: 8px;
  text-transform: uppercase;
  color: #333;
  font-size: 24px;
  width: 90%;
  margin: 0px auto;
  font-weight: bolder;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  border: 4px solid #9c27b0;
}
.slider-container {
  display: flex;
  width: 100%;
  max-width: 300px;
  /* margin-top:10px; */
  padding: 5px 10px 20px 10px;
  border: 5px white solid;
  background: #fff;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  /* Enable smooth scrolling on iOS */
  gap: 5px;
}
.slider-container:before {
  content: '';
  border-left: 20px solid transparent;
}
.slide {
  /* min-width: 100%; */
  flex: 0 0 auto;
  scroll-snap-align: start;
  width: 80px;
  height: 160px;
  /* background-color: #18b2bb; */
  border: 2px solid #fff;
  transition: 0.3s ease-in-out border;
}
.slide.active {
  border: 2px solid #333;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.loaderScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom right, #8f49f9, #d133e7);
  display: flex;
  flex-direction: column;
  color: #fff;
  z-index: 1;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.loaderScreen img {
  max-width: 300px;
}
.loaderScreen p {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.loaderScreen.hide {
  animation: fadeOut 1s forwards;
}
@keyframes fadeOut {
  from {
    opacity: 1; /* Start with full opacity */
  }
  to {
    opacity: 0; /* End with zero opacity (fully transparent) */
    visibility: hidden; /* Hide the element after the animation */
  }
}
.createYourOwn {
  position: absolute;
  /* bottom: 110px; */
  left: 50%;
  display: flex;
  gap: 15px;
  transform: translateX(-50%);
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
#uploadButton,
#selectWrapper,
#shareLink,
#gift {
  display: none;
}
.photo {
  max-width: 80%;
  max-height: 60%;
  transform: rotate(-3deg);
  border: 4px solid #fff;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
}
/* iPhone 13 Pro (portrait) */
@media only screen and (device-width: 1170px) and (device-height: 2532px) and (-webkit-device-pixel-ratio: 3) {
  /* Your CSS rules specific to the iPhone 13 Pro in portrait mode */
  .photo {
    max-height: 70%;
  }
}
/* hide on desktop */
/* Default styles for the message (hidden by default) */
.message,
.container,
.scratch-container {
  display: none;
}
/* Media query to show the message on desktop screens */
@media (min-width: 769px) {
  .message {
    display: block; /* Show the message on screens with a minimum width of 769px (desktop) */
  }
}
/* Additional styles for the message */
.message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom right, #8f49f9, #d133e7);
  display: flex;
  flex-direction: column;
  color: #fff;
  z-index: 1;
  gap: 15px;
  justify-content: center;
  align-items: center;
}
/* Media query to show content on smaller screens (e.g., mobile or tablet) */
@media (max-width: 768px) {
  .container,
  .scratch-container {
    display: flex; /* Show content on screens with a maximum width of 768px */
  }
  .message {
    display: none;
  }
}
/* Modal Styles */
.modal {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  min-width: 300px;
  min-height: 0; /* Start with a max-height of 0 to make it slide in */
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  transition: min-height 0.3s ease-in-out; /* Add a smooth transition effect */
}
.modal-content {
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  text-align: center;
  border-radius: 8px 8px 0 0;
  color: #333;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: transform 0.3s ease-in-out; /* Add a smooth transition for transform */
}
/* Add a CSS class for the animation */
.wiggle {
  animation: wiggle 0.53s ease-in-out forwards;
}

/* Add a CSS class for the one-time wiggle animation */

@keyframes wiggle {
  0%,
  100% {
    transform: translateY(0) rotate(-3deg);
  }
  25% {
    transform: translateY(-15px) rotate(-3deg);
  }
  50% {
    transform: translateY(0) rotate(-3deg);
  }
  75% {
    transform: translateY(-7px) rotate(-3deg);
  }
}
.img-book {
  width: 100%;
  max-width: 360px;
  border-radius: 3px;
  height: 200px;
  object-fit: cover;
}
.img-book-2 {
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  border-radius: 300px;
  margin: 20px auto;
}
#cc {
  background: #fff;
  text-transform: uppercase;
  color: #444;
  display: block;
  width: 280px;
  line-height: 44px;
  border-radius: 3px;
  margin: 10px auto;
  font-weight: bolder;
}
.apple {
  width: 100%;
  max-width: 100%;
  max-width: 280px;
  grid-column-gap: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #333;
  border-radius: 30px;
  align-items: center;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 0;
  /* font-family: Brandon med webfont; */
  text-decoration: none;
  display: flex;
  position: relative;
  margin: 10px auto 0px auto;
}
.bump {
  padding-bottom: 4px;

  height: 24px;
  padding-top: 0;

  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.text-block-53 {
  width: 90%;
  margin-left: 16px;
  font-size: 18px;
  text-align: left;
  line-height: 49px;
}
#congratulations {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  align-items: center;
}
#gift {
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
